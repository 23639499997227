import React, { FC, useMemo, useRef, useState } from "react"
import { Col, DatePicker, Flex, Form, Popconfirm, Row } from "antd"
import { capitalizeFirstLetter, deriveCommissionRate, deriveYearsOfService } from "@helpers"
import { UserFullInfoWithAccesses } from "@interfaces"
import { departmentsApi, usersApi } from "@state/services/subApis"
import dayjs from "dayjs"
import { useProfileLocation, useUserCommissionAbilities } from "@hooks"

import { Manager } from "../Manager"
import { deriveProfileFormValues } from "../../helpers/userConverters"
import { requiredRule } from "../../helpers/validation"
import { UserFormFields } from "../../types"
import { MAX_POSSIBLE_UPDATE_HIRE_DATE_DIFFERENCE } from "../../helpers/constants"

import { PhotoField, TagsField } from "@components/profile"
import { Field } from "@components/Field"
import { FormField } from "@components/FormField"
import { CancelButton } from "@components/buttons/CancelButton"
import { SaveButton } from "@components/buttons/SaveButton"
import { ValidatedDigitInput, ValidatedTextInput, ValidatedSelect } from "@components/ValidatedInputs"
import { Title } from "@components/Title"

import { Emails } from "./Emails"
import style from "./style.m.scss"
import { Position } from "./Position"

type UserFormProps = {
  header: string
  isEditForm?: boolean
  user?: UserFullInfoWithAccesses
  onSave: (updatedFields: UserFormFields, onFinish?: () => void) => void
  onCancel: () => void
}

const deriveMemoizedOptions = (options: Array<string>) =>
  options.map(option => ({ label: capitalizeFirstLetter(option), value: option }))

const derivePossibilityOfChangeHireDate = (user?: UserFullInfoWithAccesses) => {
  const currentHireDate = user ? user.hireDate : null
  if (currentHireDate) {
    const diff = dayjs().diff(currentHireDate, "day")
    return diff < MAX_POSSIBLE_UPDATE_HIRE_DATE_DIFFERENCE
  }

  return true
}

/* eslint max-lines-per-function: ["error", 280] */
export const UserForm: FC<UserFormProps> = ({ header, user = {}, isEditForm, onSave, onCancel }) => {
  const { isMyself } = useProfileLocation()

  const { data: options } = usersApi.endpoints.fetchUserEditOptions.useQuery()

  const { read_all: hasViewCommissionPermission } = useUserCommissionAbilities()

  const { departmentProducts } = departmentsApi.endpoints.fetchDepartmentProducts.useQuery(
    String(user.department?.id),
    {
      selectFromResult: ({ data }) => ({
        departmentProducts: data
      }),
      skip: !user.department?.id
    }
  )

  const [form] = Form.useForm<UserFormFields>()
  const [isPopconfirmVisible, setIsPopcomfirmVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const formData = useRef<UserFormFields>()

  const handlePopconfirmOk = () => {
    if (!formData.current) return

    setIsProcessing(true)
    onSave(formData.current, () => {
      setIsProcessing(false)
      setIsPopcomfirmVisible(false)
    })
  }

  const handlePopconfirmCancel = () => {
    setIsPopcomfirmVisible(false)
  }

  const handleFormSubmit = (updatedFields: UserFormFields) => {
    const hasWarning = form.getFieldWarning("position").length > 0

    if (hasWarning) {
      formData.current = updatedFields
      setIsPopcomfirmVisible(true)
    } else {
      onSave(updatedFields)
    }
  }

  const isTerminated = user.isTerminated ?? false

  const gradeOptions = useMemo(() => deriveMemoizedOptions(options?.grades || []), [options?.grades])

  const genderOptions = useMemo(() => deriveMemoizedOptions(options?.genders || []), [options?.genders])

  const selectedDepartmentId = Form.useWatch("department", form)
  const { data: selectedDepartment, isLoading } = departmentsApi.endpoints.fetchDepartmentById.useQuery(
    selectedDepartmentId?.toString() || "",
    { skip: isTerminated || !selectedDepartmentId }
  )

  return (
    <Form form={form} onFinish={handleFormSubmit} initialValues={deriveProfileFormValues(user)} disabled={isLoading}>
      <Title>
        <Flex align="center" justify="space-between" className={style.header}>
          {header}
          <div>
            <Popconfirm
              title="Are you sure you want to proceed?"
              placement="bottomLeft"
              open={isPopconfirmVisible}
              onConfirm={handlePopconfirmOk}
              okButtonProps={{ loading: isProcessing }}
              onCancel={handlePopconfirmCancel}
            >
              <SaveButton htmlType="submit" data-testid="save-employment-info" />
            </Popconfirm>

            <CancelButton onClick={onCancel} data-testid="cancel-save-employment-info" />
          </div>
        </Flex>
      </Title>

      <Flex>
        <div className={style.leftBlock}>
          <Form.Item name="photoFile">
            <PhotoField initialImageSrc={user.photo || undefined} />
          </Form.Item>
          <Form.Item name="tags">
            <TagsField tags={options?.tags || []} />
          </Form.Item>
        </div>

        <Row className={style.rightBlock}>
          <Col span={12} className={style.leftColumn}>
            <FormField
              label="First name"
              fieldName="firstName"
              rules={[requiredRule]}
              editor={<ValidatedTextInput data-testid="first-name-input" />}
            />
            <FormField
              label="Middle name"
              fieldName="secondName"
              editor={<ValidatedTextInput data-testid="second-name-input" />}
            />
            <FormField
              label="Last name"
              fieldName="lastName"
              rules={[requiredRule]}
              editor={<ValidatedTextInput data-testid="last-name-input" />}
            />
            <FormField
              label="Gender"
              fieldName="gender"
              editor={<ValidatedSelect options={genderOptions} data-testid="gender-select" />}
            />
            <FormField
              label="Birth date"
              fieldName="birthDate"
              editor={<DatePicker data-testid="birth-date-picker" />}
            />
            <FormField
              label="Hire date"
              fieldName="hireDate"
              rules={[requiredRule]}
              editor={
                <DatePicker
                  data-testid="hire-date-picker"
                  disabled={isEditForm ? !derivePossibilityOfChangeHireDate(user) : false}
                />
              }
            />

            {isEditForm && (
              <Field
                title="Separation date"
                value={user.separationDate}
                className={style.field}
                data-testid="separation-date"
              />
            )}

            <Field
              title="Tenure"
              value={isEditForm ? (user.yearsOfService ? deriveYearsOfService(user.yearsOfService) : "") : "0 month(s)"}
              className={style.field}
              data-testid="years-of-service"
            />
            {isEditForm ? (
              <Field title="Location" value={user.location} className={style.field} />
            ) : (
              <FormField
                label="Location"
                fieldName="locationId"
                editor={
                  <ValidatedSelect
                    options={options?.locations || []}
                    optionFilterProp="label"
                    showSearch
                    data-testid="location-select"
                  />
                }
                rules={[requiredRule]}
              />
            )}
            <Emails form={form} />

            <FormField
              label="Phone number"
              fieldName="phone"
              editor={<ValidatedDigitInput prefix="+" data-testid="phone-input" />}
            />
          </Col>

          <Col span={12} className={style.leftColumn}>
            {isTerminated ? (
              <>
                <Field title="Department" value="" className={style.field} data-testid="department" />
                <Field title="Job title" value="" className={style.field} data-testid="position" />
              </>
            ) : (
              <Position
                form={form}
                user={user}
                departments={options?.departments || []}
                departmentProducts={departmentProducts}
                selectedDepartment={selectedDepartment}
              />
            )}
            <FormField
              label="Grade"
              fieldName="grade"
              editor={<ValidatedSelect options={gradeOptions} data-testid="grade-select" />}
            />
            {(isMyself || hasViewCommissionPermission) && user.hasCommission && (
              <Field
                title="Commission rate"
                value={deriveCommissionRate(user.commissionRate ?? null)}
                className={style.field}
                dataTestId="commission-rate"
              />
            )}
            <Field title="Direct manager" value={<Manager manager={user.directManager} />} className={style.field} />

            {isTerminated ? (
              <Field
                title="Employment status"
                value={user.employmentStatus?.description}
                className={style.field}
                data-testid="employment-status"
              />
            ) : (
              <FormField
                label="Employment status"
                fieldName="employmentStatus"
                editor={
                  <ValidatedSelect options={options?.employmentStatuses || []} data-testid="employment-status-select" />
                }
              />
            )}

            <FormField
              label="Work format"
              fieldName="workFormat"
              editor={<ValidatedSelect options={options?.workFormats || []} data-testid="work-format-select" />}
            />
            <FormField
              label="Work schedule"
              fieldName="workSchedule"
              editor={<ValidatedSelect options={options?.workSchedules || []} data-testid="work-schedule-select" />}
            />
            <FormField
              label="Product"
              fieldName="products"
              rules={[requiredRule]}
              editor={
                <ValidatedSelect options={options?.products || []} mode="multiple" data-testid="products-select" />
              }
            />
            {isEditForm && user.employeeType !== undefined && (
              <FormField
                label="Employee type"
                fieldName="employeeType"
                editor={<ValidatedSelect options={options?.employeeTypes} data-testid="employee-type-select" />}
              />
            )}
            {isEditForm && user.availableSystems !== undefined && (
              <FormField
                label="Available systems"
                fieldName="availableSystems"
                editor={
                  <ValidatedSelect
                    options={options?.availableSystems || []}
                    mode="multiple"
                    data-testid="available-systems-select"
                  />
                }
              />
            )}
          </Col>
        </Row>
      </Flex>
    </Form>
  )
}
