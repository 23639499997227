import React, { FC, useState } from "react"
import dayjs from "dayjs"
import { Button, DatePicker, Flex, Form, Segmented, Tag } from "antd"
import "./expiration-datepicker.scss"
import { LocationCardSettings } from "@interfaces*"
import { SyncOutlined, ToolOutlined } from "@ant-design/icons"

import { QuotaFormItem } from "../components/QuotaFormItem"
import { expirationOptions, leaveTypeOptions, workdaysOptions } from "../helpers/quotaFormOptions"
import { parseQuotas } from "../helpers/parseQuotas"

import { ValidatedSelect, ValidatedTextArea } from "@components/ValidatedInputs"

import QuotaInput from "./QuotaInput"

type Props = {
  settings: LocationCardSettings["setting"]["attributes"]
  quotas: LocationCardSettings["quotas"]
  handleFieldsChange: () => void
}

export const LocationFormFields: FC<Props> = ({ settings, quotas, handleFieldsChange }) => {
  const [expiration, setExpiration] = useState(settings.expiration ? "expired" : "")
  const [isDisabled, setIsDisabled] = useState(settings.brought_forward_limit === null)
  const form = Form.useFormInstance()
  const handleExpirationChange = (value: string) => {
    setExpiration(value)
  }

  const { calculableQuotas, otherQuotas } = parseQuotas(quotas)

  const planningStartDate = dayjs(settings.planning_dates.planning_start_date)
  const planningEndDate = dayjs(settings.planning_dates.planning_end_date)

  const handleClickIcon = () => {
    setIsDisabled(!isDisabled)
    !isDisabled && form.setFieldValue("brought_forward_limit", null)
    handleFieldsChange()
  }

  return (
    <>
      {calculableQuotas.map(
        quota =>
          quota && <QuotaInput key={quota.attributes.category} quota={quota} handleFieldsChange={handleFieldsChange} />
      )}

      <Form.Item
        name="planning_dates"
        label={<span style={{ whiteSpace: "normal", width: "130px" }}>Paid time-off planning deadline</span>}
        initialValue={[planningStartDate, planningEndDate]}
      >
        <DatePicker.RangePicker allowClear={false} />
      </Form.Item>

      <Form.Item name="expiration" label="Type of Paid time off" initialValue={expiration}>
        <Segmented value={expiration} options={expirationOptions} onChange={handleExpirationChange} />
      </Form.Item>

      {!!expiration && (
        <Form.Item
          name="expiration_date"
          label="Expiration date"
          initialValue={dayjs(settings.expiration ? settings.expiration : "01 01 2000")}
        >
          <DatePicker popupClassName="expiration-datepicker" showToday={false} format="MMMM DD" />
        </Form.Item>
      )}

      <QuotaFormItem
        name={["brought_forward_limit"]}
        label={<span style={{ whiteSpace: "normal", width: "130px" }}>Days to brought forward</span>}
        initialValue={settings.brought_forward_limit}
        placeholder="Unlimited"
        disabled={isDisabled}
        permanent
        calculable={false}
        handleClickIcon={handleClickIcon}
        handleDisabled={handleClickIcon}
        addon={
          <>
            {isDisabled ? (
              <Button shape="circle" size="small" type="text" onClick={handleClickIcon}>
                <SyncOutlined />
              </Button>
            ) : (
              <Button shape="circle" size="small" type="text" onClick={handleClickIcon}>
                <ToolOutlined />
              </Button>
            )}
          </>
        }
      />

      {otherQuotas.map(
        quota =>
          quota && <QuotaInput key={quota.attributes.category} quota={quota} handleFieldsChange={handleFieldsChange} />
      )}

      <Form.Item name="leave_type" label="Type of leaves" initialValue={settings.leave_type}>
        <ValidatedSelect options={leaveTypeOptions} />
      </Form.Item>

      <Form.Item name="workdays" label="Workdays" initialValue={settings.workdays || []}>
        <ValidatedSelect
          mode="multiple"
          showSearch={false}
          options={workdaysOptions}
          tagRender={({ value }) => <Tag style={{ margin: 2 }}>{value}</Tag>}
        />
      </Form.Item>

      <Form.Item
        name="tooltip"
        colon={false}
        label={
          <Flex wrap="wrap" justify="flex-end">
            <span>Tooltip for</span>
            <span>Remaining days :</span>
          </Flex>
        }
        initialValue={settings.tooltip || ""}
      >
        <ValidatedTextArea rows={2} />
      </Form.Item>
    </>
  )
}
