import { LocationCardSettings, TLocationFormFields, VacationQuota } from "@interfaces*"
import { Button, Form, Typography } from "antd"
import React, { FC, useState } from "react"
import dayjs from "dayjs"
import { vacationsSettingsApi } from "@state/services/subApis"

import LocationFormFields from "../LocationFormFields"
import { leaveTypeOptions } from "../helpers/quotaFormOptions"
import { separateQuotaFormFields } from "../helpers/separateQuotaFormFields"
import { useSettingsCreate } from "../helpers/useSettingsCreate"

import { ValidatedSelect } from "@components/ValidatedInputs"

const currentYear = dayjs().year()
const DEFAULT_START_PLANNING_DATE = `${currentYear}-12-01`
const DEFAULT_END_PLANNING_DATE = `${currentYear}-03-31`

const DefaultSettings: LocationCardSettings["setting"]["attributes"] = {
  expiration: null,
  leave_type: leaveTypeOptions[0].value,
  location_id: "Null",
  tooltip: "",
  brought_forward_limit: null,
  planning_dates: {
    planning_start_date: DEFAULT_START_PLANNING_DATE,
    planning_end_date: DEFAULT_END_PLANNING_DATE
  },
  workdays: ["Mo", "Tu", "We", "Th", "Fr"]
}

const DefaultQuota: VacationQuota = {
  id: "Null",
  type: "vacation_quota",
  attributes: {
    days: null,
    calculable: false,
    permanent: false,
    category: "Null",
    category_id: null,
    vacation_setting_id: "Null"
  }
}

export const NewVacationsSettingsForm: FC<{ modalClose: () => void }> = ({ modalClose }) => {
  const [chosen, setChosen] = useState(true)
  const createSetting = useSettingsCreate()

  const { locationsOptions, categories } = vacationsSettingsApi.endpoints.fetchVacationSettingsOptions.useQuery(null, {
    selectFromResult: ({ data }) => ({
      locationsOptions: data?.available_locations || [],
      categories: data?.categories || []
    })
  })

  const initialQuotas: Array<VacationQuota> = categories.map(({ label, calculable, value }) => ({
    ...DefaultQuota,
    attributes: { ...DefaultQuota.attributes, category: label, calculable, category_id: value }
  }))

  const newLocationFormFinish = (fields: TLocationFormFields) => {
    const { newQuotas, newSettings, location } = separateQuotaFormFields(fields)
    createSetting(location, newSettings, newQuotas)
    modalClose()
  }

  const handleSelectChange = () => {
    setChosen(false)
  }

  return (
    <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} onFinish={newLocationFormFinish}>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Typography.Title style={{ margin: "1rem 0 0 0", textAlign: "center" }} level={4}>
          Add new vacations location
        </Typography.Title>
      </Form.Item>

      <Form.Item name="location" label="Location" required>
        <ValidatedSelect options={locationsOptions} onChange={handleSelectChange} />
      </Form.Item>

      <LocationFormFields settings={DefaultSettings} quotas={initialQuotas} handleFieldsChange={() => {}} />

      <Form.Item label=" " colon={false}>
        <Button type="primary" htmlType="submit" disabled={chosen}>
          Create
        </Button>
      </Form.Item>
    </Form>
  )
}
