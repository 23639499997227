import React from "react"
import { capitalizeFirstLetter, generateFullName } from "@helpers"
import { DepartmentStatus, DepartmentsColumns } from "@enums"
import { Chief, Department } from "@interfaces*"
import { Tooltip } from "antd"
import { Link } from "react-router-dom"
import { generateDepartmentRoute } from "@routes/inboundRoutes"
import { departmentsApi, usersApi } from "@state/services/subApis"

export const statusSelectOptions = [
  { value: DepartmentStatus.Active, label: DepartmentStatus.Active },
  { value: DepartmentStatus.Inactive, label: DepartmentStatus.Inactive },
  { value: DepartmentStatus.All, label: DepartmentStatus.All }
]

export const useDepartmentsUtility = () => {
  const { headsOfDepartments } = usersApi.endpoints.fetchUsersForFilters.useQuery(
    {
      "filter[chief_of_department]": String(true),
      "fields[users]": "first_name,second_name,last_name"
    },
    {
      selectFromResult: ({ data }) => ({
        headsOfDepartments: data || []
      })
    }
  )

  const { departmentsOptions } = departmentsApi.endpoints.fetchDepartmentsOptions.useQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        departmentsOptions: data?.options || []
      })
    }
  )

  const departmentsColumns = [
    {
      title: DepartmentsColumns.DepartmentName,
      key: "departmentName",
      render: (_: unknown, { id, name }: Department) => {
        return (
          <Tooltip placement="top" title={name}>
            <Link to={generateDepartmentRoute(id)}>{name}</Link>
          </Tooltip>
        )
      }
    },
    {
      title: DepartmentsColumns.Status,
      dataIndex: "status",
      key: "status",
      render: (value: string) => capitalizeFirstLetter(value)
    },
    {
      title: DepartmentsColumns.HeadOfDepartment,
      dataIndex: "chief",
      key: "headOfDept",
      render: (chief: Chief | null) =>
        chief ? (
          <Tooltip placement="top" title={generateFullName(chief.lastName, chief.firstName)}>
            {generateFullName(chief.lastName, chief.firstName)}
          </Tooltip>
        ) : (
          "-"
        )
    },
    {
      title: DepartmentsColumns.ParentDepartment,
      dataIndex: "parentDepartment",
      key: "parentDept",
      render: (_: unknown, { parentDept }: Department) => {
        if (!parentDept) return "-"
        return (
          <Tooltip placement="top" title={parentDept.name}>
            <Link to={generateDepartmentRoute(parentDept.id)}>{parentDept.name}</Link>
          </Tooltip>
        )
      }
    },
    {
      title: DepartmentsColumns.SubordinateDepartment,
      dataIndex: "subordinateDept",
      key: "subordinateDept",
      render: (_: unknown, { subordinateDepsCount }: Department) => subordinateDepsCount
    },
    {
      title: DepartmentsColumns.Jobs,
      dataIndex: "jobs",
      key: "jobs",
      render: (_: unknown, { allPositionsCount, occupiedPositionsCount }: Department) =>
        `${allPositionsCount}/${occupiedPositionsCount}`
    }
  ]

  const headOfDepartmentsOptions = headsOfDepartments.map(user => ({ value: user.id, label: user.name }))

  return { departmentsColumns, headOfDepartmentsOptions, departmentsOptions }
}
