import React, { FC } from "react"
import { Col, Flex, Row } from "antd"
import { UserFullInfoWithAccesses } from "@interfaces"
import { useProfileLocation, useUserCommissionAbilities, useUserProfileEmploymentInfoAbilities } from "@hooks"
import { capitalizeFirstLetter, deriveCommissionRate, deriveYearsOfService } from "@helpers"
import { usersApi } from "@state/services/subApis"

import { Manager } from "../../../components/Manager"
import { Termination } from "../components/Termination"
import { Transfer } from "../components/Transfer"
import { AvailableSystemsList } from "../components/AvailableSystemsList"

import { StandalonePhoto, Tags, Phone } from "@components/profile"
import { Field } from "@components/Field"
import { EditButton } from "@components/buttons/EditButton"
import { Title } from "@components/Title"
import { Gender } from "@components/profile/Gender"

import style from "./style.m.scss"

type ViewPanelProps = {
  user: UserFullInfoWithAccesses
  isFetching: boolean
  onTotalEdit?: () => void
}

export const ViewPanel: FC<ViewPanelProps> = ({ user, isFetching, onTotalEdit }) => {
  const { isMyself } = useProfileLocation()

  const { data: options } = usersApi.endpoints.fetchUserEditOptions.useQuery()

  const { read_all: viewSection, update_all: updateSection } = useUserProfileEmploymentInfoAbilities()

  const { read_all: hasViewCommissionPermission } = useUserCommissionAbilities()

  const isFieldVisible = <T extends unknown>(value: T | undefined) =>
    Boolean(viewSection || updateSection || value !== undefined)

  const {
    additionalEmails,
    accesses,
    birthDate,
    corporateEmail,
    status,
    firstName,
    grade,
    hireDate,
    lastName,
    location,
    phone,
    photo,
    products,
    secondName,
    separationDate,
    tags,
    workFormat,
    workSchedule,
    yearsOfService,
    directManager,
    department,
    position,
    commissionRate,
    hasCommission,
    availableSystems,
    gender,
    employeeType
  } = user

  const photoSrc = isFetching ? undefined : photo

  return (
    <>
      <Flex justify="flex-end">
        {Boolean(onTotalEdit) && (
          <Flex gap="15px">
            <div className={style.action}>
              <Termination user={user} />
            </div>
            {!user.isTerminated && (
              <div className={style.action}>
                <Transfer user={user} />
              </div>
            )}
          </Flex>
        )}
      </Flex>
      <Title>
        <Flex align="center" justify="space-between" className={style.header}>
          Employment information {onTotalEdit && <EditButton onClick={onTotalEdit} data-testid="profile-total-edit" />}
        </Flex>
      </Title>

      <Flex>
        <div className={style.leftBlock}>
          <StandalonePhoto isEditable={!updateSection && accesses?.photo.writable} src={photoSrc ?? undefined} />
          {isFieldVisible(tags) && (
            <Tags isEditable={Boolean(!updateSection && accesses?.tags.writable)} value={tags} />
          )}
        </div>
        <Row className={style.rightBlock}>
          <Col span={12} className={style.leftColumn}>
            <Field title="Name" value={[firstName, secondName, lastName].filter(Boolean).join(" ")} />
            <Gender value={gender || ""} options={options?.genders ?? []} isEditable={isMyself || !!updateSection} />
            {isFieldVisible(birthDate) && <Field title="Birth date" value={birthDate} dataTestId="birth-date" />}
            {isFieldVisible(hireDate) && <Field title="Hire date" value={hireDate} dataTestId="hire-date" />}
            {isFieldVisible(separationDate) && (
              <Field title="Separation date" value={separationDate} dataTestId="separation-date" />
            )}
            {isFieldVisible(yearsOfService) && (
              <Field
                title="Tenure"
                value={yearsOfService ? deriveYearsOfService(yearsOfService) : ""}
                dataTestId="years-of-service"
              />
            )}
            {isFieldVisible(location) && <Field title="Location" value={location} dataTestId="location" />}
            {isFieldVisible(corporateEmail) && <Field title="Email" value={corporateEmail} dataTestId="email" />}
            {additionalEmails?.length && additionalEmails.length > 0 ? (
              <Field
                title="Additional emails"
                value={<div className={style.additionalEmails}>{additionalEmails?.join(", ")}</div>}
                dataTestId="additional-emails"
              />
            ) : null}
            {isFieldVisible(phone) && (
              <Phone value={phone || ""} isEditable={Boolean(!updateSection && accesses?.phone?.writable)} />
            )}
          </Col>
          <Col span={12} className={style.rightColumn}>
            {isFieldVisible(department) && (
              <Field title="Department" value={department?.name} dataTestId="department" />
            )}
            {isFieldVisible(position) && <Field title="Job title" value={position?.name} dataTestId="job-title" />}
            {isFieldVisible(grade) && (
              <Field title="Grade" value={capitalizeFirstLetter(grade || "")} dataTestId="grade" />
            )}
            {(isMyself || hasViewCommissionPermission) && hasCommission && (
              <Field
                title="Commission rate"
                value={deriveCommissionRate(commissionRate ?? null)}
                dataTestId="commission-rate"
              />
            )}
            {isFieldVisible(directManager) && (
              <Field title="Direct manager" value={<Manager manager={directManager} />} />
            )}
            {isFieldVisible(status) && (
              <Field title="Employment status" value={status} dataTestId="employment-status" />
            )}
            {isFieldVisible(workFormat) && (
              <Field title="Work format" value={workFormat?.description} dataTestId="work-format" />
            )}
            {isFieldVisible(workSchedule) && (
              <Field title="Work schedule" value={workSchedule?.description} dataTestId="work-schedule" />
            )}
            {isFieldVisible(products) && (
              <Field
                title="Product"
                value={products?.map(product => capitalizeFirstLetter(product)).join(", ")}
                dataTestId="products"
              />
            )}
            {isFieldVisible(employeeType) && (
              <Field
                title="Employee type"
                value={employeeType === "eor" ? "EOR" : capitalizeFirstLetter(employeeType || "")}
                dataTestId="employee-type"
              />
            )}
            {isFieldVisible(availableSystems) && (
              <Field
                title="Available systems"
                value={<AvailableSystemsList availableSystems={availableSystems} />}
                dataTestId="available-systems"
              />
            )}
          </Col>
        </Row>
      </Flex>
    </>
  )
}
